import Vue from 'vue'
import Router from 'vue-router'
// import factory from "./factory/index";

import system from './system.js'
import collection from './collection.js'
import equipment from './equipment.js'
import account from './account.js'
import marketing from './marketing.js'
// import dealer from './dealer.js'
// import operate from './operate.js'

Vue.use(Router)

export default new Router({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      name: 'login',
      component: () => import('@/views/Login'),
    },
    {
      path: '/main',
      name: 'home',
      component: () => import('@/layout/main'),
      // redirect: {name: 'marketing'},
      children: [
        {...system},
        {...collection},
        {...equipment},
        {...account},
        {...marketing},
      ]
    },
//临时特殊处理
    {
      path: '/drcofffee',
      name: 'drcoffee',

      component: () => import('@/layout/drcoffee'),
      children: [
        {
          path: 'quickPay',
          name: 'collection.quickpay',
          meta: {
            bg: false,
            break: true,
            title: '支付配置',
          },
          component: () => import('@/views/Collection/QuickPay/index'),
          redirect: {name: 'collection.quickPay.ali'},
          children: [
            {
              path: 'ali',
              name: 'collection.quickPay.ali',
              meta: {
                bg: false,
                break: true,
                title: '支付宝支付',
              },
              component: () => import('@/views/Collection/QuickPay/ali/index'),
            },
            {
              path: 'ali/config',
              name: 'collection.pay.quickPay.ali.config',
              meta: {
                bg: false,
                break: true,
                title: '配置支付宝商户',
              },
              component: () => import('@/views/Collection/QuickPay/ali/aliConfig'),
            },
            {
              path: 'wx',
              name: 'collection.quickPay.wx',
              meta: {
                bg: false,
                break: true,
                title: '微信支付',
              },
              component: () => import('@/views/Collection/Pay/wx/index'),
            },
            {
              path: 'wx/config',
              name: 'collection.quickPay.wx.config',
              meta: {
                bg: false,
                break: true,
                title: '配置微信商户',
              },
              component: () => import('@/views/Collection/Pay/wx/wxConfig'),
            },
          ]
        },
      ]

    },
    {
      path: '*',    // 此处需特别注意至于最底部
      redirect: '/main'
    }
  ]
})

