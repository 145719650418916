/**
 * Created by fworld on 2019/12/15.
 */
export default {
  navMenus: state => state.account.navMenus, // 导航菜单
  selectNavMenu: state => state.account.selectNavMenu, // 导航菜单
  menus: state => state.account.menus, // 导航菜单
  employee: state => state.account.employee, // 导航菜单
  accountPage: state => state.account.page, // 导航菜单
  accountGroups: state => state.account.groups, // 导航菜单
  theme: state => state.config.theme,
  factoryProductNames: state => state.factory.productNames,
  companyItem: state => state.marketing.companyItem,
}
