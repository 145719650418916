// import Cookies from 'js-cookie'




export function getToken(key='token') {
  return localStorage.getItem(key);
}

export function setToken(token, key='token') {
  return localStorage.setItem(key, token)
}

export function removeToken(key='token') {
  return localStorage.removeItem(key)
}
