const type = {
  //login
  THEME: `THEME`,

  // account_enterprise
  SET_ENTERPRISE_NAV_MENU: 'SET_ENTERPRISE_NAV_MENU',
  SET_ENTERPRISE_EMPLOYEE: 'SET_ENTERPRISE_EMPLOYEE',
  SET_ACCOUNT_PAGE: 'SET_ACCOUNT_PAGE',
  SET_SELECT_MENUS: 'SET_SELECT_MENUS',
  SET_ACCOUNT_GROUP: 'SET_ACCOUNT_GROUP',
  SET_PRODUCT_NAME: 'SET_PRODUCT_NAME',

  SET_COMPANY_ITEM : 'SET_COMPANY_ITEM',


}
export default type




