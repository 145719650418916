/**
 * Created by fworld on 2019/6/15.
 */
import request from '@/util/request'

/**
 * 企业产品列表
 * @returns {AxiosPromise}
 */
export const productUnion = (params) => {
    return request({
        url: `/api/v1/factory/product/union`,
        method: 'get',
        params
    })
}

/**
 * 企业产品搜索
 * @param name
 * @returns {AxiosPromise}
 */
export const enterpriseSearchProduct = (name) => {
    return request({
        url: `/api/v1/hardware/enterprise/searchProduct?name=${name}`,
        method: 'get'
    })
}

/**
 * 产品创建
 * @returns {AxiosPromise}
 */
export const productCreate = (data) => {
    return request({
        url: `/api/v1/factory/product/create`,
        method: 'post',
        data
    })
}


export const productList = (params) => {
    return request({
        url: `/api/v1/factory/product/list`,
        method: 'get',
        params
    })
}

export const productAll = () => {
    return request({
        url: `/api/v1/factory/product/all`,
        method: 'get',
    })
}

export const productSecret = (uuid) => {
    return request({
        url: `/api/v1/factory/product/secret?uuid=${uuid}`,
        method: 'get',
    })
}

export const productDelete = (uuid) => {
    return request({
        url: `/api/v1/factory/product/delete?uuid=${uuid}`,
        method: 'get',
    })
}

export const productConf = (uuid) => {
    return request({
        url: `/api/v1/factory/product/conf?uuid=${uuid}`,
        method: 'get',
    })
}

export const productConfUpdate = (data) => {
    return request({
        url: `/api/v1/factory/product/conf/update`,
        method: 'post',
        data
    })
}
