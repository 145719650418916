import axios from 'axios'
import router from '../router/index'
import {getToken} from "./auth";
import {removeToken} from './auth';
import {Message, Loading} from 'element-ui'

const service = axios.create({
  // baseURL: 'http://localhost:8076', // 商城api 的 base_url
   baseURL: 'https://v2.yunka-it.cn', // 商城api 的 base_url
  // baseURL: 'http://hardware.it.iyyclub.com', // 商城api 的 base_url
  // baseURL: 'https://api.dr-coffee.cn', // 商城api 的 base_url
  timeout: 1000000, // request timeout
})

let loading;

// 请求合并只出现一次loading
// 当前正在请求的数量
let needLoadingRequestCount = 0;

/* 显示loading */
function showLoading(target) {
  // 后面这个判断很重要，因为关闭时加了抖动，此时loading对象可能还存在，
  // 但needLoadingRequestCount已经变成0.避免这种情况下会重新创建个loading
  if (needLoadingRequestCount === 0 && !loading) {
    loading = Loading.service({
      lock: true,
      text: "数据加载中，请耐心等候...",
      background: "rgba(0,0,0,0.83)",
      target: target || "body"
    });
  }
  needLoadingRequestCount++;
}

/* 隐藏loading */
function hideLoading() {
  if (needLoadingRequestCount <= 0) return;
  needLoadingRequestCount--;
  if (needLoadingRequestCount === 0 && loading) {
    loading.close();
    loading = null;
  }
}


service.interceptors.request.use(
  config => {
    let url = config.url,
      token = getToken();
    if (token && url.indexOf('token') < 0) {
      if (url.indexOf('?') < 0) {
        url = url + `?token=${token}`;
      } else {
        url = url + `&token=${token}`;
      }
    }
    config.url = url;
    showLoading();

    return config
  },
  error => {
    hideLoading();
    Promise.reject(error)
  }
)

// response interceptor
service.interceptors.response.use(
  response => {
    hideLoading();
    if (response.status === 200) {
      response.data.code = parseInt(response.data.code, 10)
      if (response.data.code === 7) {
        removeToken();
        router.push({name: 'login'})
        return
      }

      if (response.data.code !== 0) {

        Message.error(response.data.msg)
        return Promise.reject()
      }
    }
    return response.data
  },
  error => {
    hideLoading();
    console.log('err' + error)
    Message.error('网络异常')
    return Promise.reject(error)
  }
)

export default service
