import type from '../mutationTypes'

const config = {
  state: {
    theme: 'light',
  },
  mutations: {
    [type.THEME](state, theme) {
      state.theme = theme
    },
  },
  actions: {
    setTheme({ commit }, theme){
        commit(type.THEME, theme);
    }
  }

}
export default config
