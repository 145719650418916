import type from '../mutationTypes'

const marketing = {
  state: {
    companyItem: {
      uuid:''
    },
  },
  mutations: {
    [type.SET_COMPANY_ITEM](state, companyItem) {
      state.companyItem = companyItem
    },
  },
  actions: {
    setCompanyItem({ commit }, companyItem){
      commit(type.SET_COMPANY_ITEM, companyItem);
    }
  }

}
export default marketing
