export default {
  path: 'system',
  name: 'system',
  component: () => import('@/views/redirect'),
  meta: {
    title: '',
  },
  children: [
    {
      path: 'application',
      name: 'system.application',
      meta: {
        bg: false,
        break: true,
        title:'系统应用'
      },
      redirect: {name: 'system.application.moduleSort'},
      component: () => import('@/views/System/Application/index'),
      children: [{
        path: 'module',
        name: 'system.application.module',
        component: () => import('@/views/System/Application/module'),
        meta: {
          bg: true,
          break: true,
        }
      },{
        path: 'moduleSort',
        name: 'system.application.moduleSort',
        component: () => import('@/views/System/Application/moduleSort'),
        meta: {
          bg: true,
          break: true,
        }
      }]
    },
    {
      path: 'enterprise',
      name: 'system.enterprise',
      meta: {
        bg: false,
        break: true,
        title:'经销商户'
      },
      redirect: {name: 'system.enterprise.list'},
      component: () => import('@/views/redirect'),
      children: [{
        path: 'list',
        name: 'system.enterprise.list',
        component: () => import('@/views/System/Enterprise/list'),
        meta: {
          bg: true,
          break: true,
        }
      }, {
        path: 'detail',
        name: 'system.enterprise.detail',
        component: () => import('@/views/System/Enterprise/detail'),
        meta: {
          bg: true,
          break: false,
        }
      }]
    },
    {
      path: 'product',
      name: 'system.product',
      meta: {
        bg: false,
        break: true,
        title:'产品管理'
      },
      redirect: {name: 'system.product.index'},
      component: () => import('@/views/redirect'),
      children: [
        {
          path: 'index',
          name: 'system.product.index',
          component: () => import('@/views/System/Product/index'),
          meta: {
            bg: true,
            break: true,
          }
        }, {
          path: 'detail',
          name: 'system.product.detail',
          component: () => import('@/views/System/Product/detail'),
          meta: {
            bg: true,
            break: false,
          }
        }]
    },
    {
      path: 'blacklist',
      name: 'system.blacklist',
      meta: {
        bg: false,
        break: true,
        title:'黑名单'
      }
    },
    {
      path: 'group',
      name: 'system.group',
      meta: {
        bg: false,
        break: true,
        title:'分组管理'
      }
    },
    {
      path: 'user',
      name: 'system.user',
      meta: {
        bg: false,
        break: true,
        title:'用户列表'
      }
    },
  ]
}
