import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './plugins/element.js'
import './assets/js/rem'
import './assets/style/reset.css'

import VueQrcode from '@chenfengyuan/vue-qrcode';

Vue.component(VueQrcode.name, VueQrcode);

import {getToken, setToken, removeToken} from '@/util/auth'
import {employeeRefresh} from "@/api/account/employee";
import Mq from "./util/mq";
import filters from './util/filters'

import RouterBack from './components/RouterBack'
import Empty from './components/Empty'
Vue.component('router-back', RouterBack)
Vue.component('empty', Empty)
Vue.config.productionTip = false

Date.prototype.Format = function (fmt) { // author: meizz
  var o = {
    "M+": this.getMonth() + 1, // 月份
    "d+": this.getDate(), // 日
    "h+": this.getHours(), // 小时
    "m+": this.getMinutes(), // 分
    "s+": this.getSeconds(), // 秒
    "q+": Math.floor((this.getMonth() + 3) / 3), // 季度
    "S": this.getMilliseconds() // 毫秒
  };
  if (/(y+)/.test(fmt))
    fmt = fmt.replace(RegExp.$1, (this.getFullYear() + "").substr(4 - RegExp.$1.length));
  for (var k in o)
    if (new RegExp("(" + k + ")").test(fmt)) fmt = fmt.replace(RegExp.$1, (RegExp.$1.length == 1) ? (o[k]) : (("00" + o[k]).substr(("" + o[k]).length)));
  return fmt;
}


Vue.prototype.$mq = new Mq();

let vue = new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')

if (vue.$route.name !== 'login') {
  let token = getToken()
  if (token) {
    employeeRefresh({token: getToken}).then(res => {
      if (res.code === 0) {
        setToken(res.data.refreshToken);
        vue.$store.dispatch('setEnterpriseEmployee', res.data.employee);
      }
    }).catch(() => {
      removeToken();
      vue.$router.push({path: "/"});
    })
  }
}

