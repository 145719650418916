/**
 * Created by fworld on 2019/12/21.
 */
export default {
  path: 'equipment',
  name: 'equipment',
  component: () => import('@/views/Equipment/redirect'),
  meta: {
    title: '',
  },
  children: [
    {
      path: 'drink',
      name: 'equipment.drink',
      meta: {
        bg: true,
        break: true,
        title: '设备饮品',
      },
      redirect:{name: 'equipment.drink.item'},
      component: () => import('@/views/redirect'),
      children:[
        {
          path: 'item',
          name: 'equipment.drink.item',
          meta: {
            bg: true,
            break: true,
            title: '设备列表',
          },
          component: () => import('@/views/Equipment/Drink/index'),
        },
        {
          path: 'edit',
          name: 'equipment.drink.edit',
          meta: {
            bg: false,
            break: true,
            title: '饮品编辑',
          },
          component: () => import('@/views/Equipment/Drink/Drink/edit'),
        },
        {
          path: 'create',
          name: 'equipment.drink.create',
          meta: {
            bg: false,
            break: true,
            title: '饮品创建',
          },
          component: () => import('@/views/Equipment/Drink/Drink/create'),
        },
      ]
    },
    {
      path: 'manager',
      name: 'equipment.manager',
      meta: {
        bg: false,
        break: true,
        title: '设备管控',
      },
      component: () => import('@/views/Equipment/Manager/index'),
    },
    {
      path: 'maintains',
      name: 'equipment.maintains',
      meta: {
        bg: false,
        break: true,
        title: '保养派工',
      }
    },
    {
      path: 'faults',
      name: 'equipment.faults',
      meta: {
        bg: true,
        break: true,
        title: '故障预警',
      },
      component: () => import('@/views/Equipment/Faults/index'),
    },
    {
      path: 'report',
      name: 'equipment.report',
      meta: {
        bg: false,
        break: true,
        title: '维护报告',
      }
    },

  ]
}
