const combination = {
  path: 'combination',
  name: 'marketing.combination',
  meta: {
    bg: false,
    break: true,
    title: ''
  },
  redirect: {name: 'marketing.combination.companies'},
  component: () => import('@/views/redirect'),
  children: [
    {
      path: 'list',
      name: 'marketing.combination.companies',
      component: () => import('@/views/Marketing/Combination/Company/index'),
      meta: {
        title: '客户运营'
      },
    },
    {
      path: 'detail',
      name: 'marketing.combination.companies.detail',
      meta: {
        title: '客户详情'
      },
      component: () => import('@/views/Marketing/Combination/Company/detail'),
    },
    {
      path: 'create',
      name: 'marketing.combination.create',
      component: () => import('@/views/Marketing/Combination/create'),
      meta: {
        title: '创建套餐'
      },
    },
    {
      path: 'edit',
      name: 'marketing.combination.edit',
      component: () => import('@/views/Marketing/Combination/edit'),
      meta: {
        title: '编辑套餐'
      },
    },
  ]
}

export default combination;
