function Mq() {
}

Mq.prototype.topics = []
Mq.prototype.Subcribe = function (topic, handler) {
  if (!this.topics[topic]) { //如果没有订阅过此类消息，创建一个缓存列表
    this.topics[topic] = [];
  }
  this.topics[topic].push(handler);
}

Mq.prototype.UnSubcribe = function (topic, handler) {
  let fns = this.topics[topic];
  if (!fns) {
    return false;
  }
  if (!handler) {
    return fns && (fns.length = 0);
  } else {
    for (let index = 0; index < fns.length; index++) {
      const _fn = fns[index];
      if (_fn === handler) {
        fns.splice(index, 1);
      }
    }
  }
}
Mq.prototype.Publish = function (topic, data) {
  let fns = this.topics[topic];
  if (!fns) {
    return;
  }

  for (let index = 0; index < fns.length; index++) {
    const _fn = fns[index];
    _fn(data)
  }

}


export default Mq
