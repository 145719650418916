/**
 * Created by fworld on 2019/12/21.
 */
export default {
  path: 'account',
  name: 'account',
  redirect: {name: 'account.employees'},
  component: () => import('@/views/Account/redirect'),
  meta: {
    title: '',
  },
  children: [
    {
      path: 'brand',
      name: 'account.brand',
      meta: {
        bg: true,
        break: true,
        title: '企业品牌'
      }
    },
    {
      path: 'employees',
      name: 'account.employees',
      meta: {
        bg: true,
        break: true,
        title: '成员管理'
      },
      component: () => import('@/views/Account/Employee'),
    },
    {
      path: 'departments',
      name: 'account.departments',
      meta: {
        bg: true,
        break: true,
        title: '部门管理'
      },
      component: () => import('@/views/Account/Department'),
    },
    {
      path: 'roles',
      name: 'account.roles',
      meta: {
        bg: true,
        break: true,
        title: '角色权限'
      },
      component: () => import('@/views/Account/Role'),
    },
    {
      path: 'bill',
      name: 'account.bill',
      meta: {
        bg: true,
        break: true,
        title: '企业账单'
      }
    },
  ]
}
