/**
 * Created by fworld on 2019/12/21.
 */
export default {
  path: 'collection',
  name: 'collection',
  meta: {
    title: '',
  },
  component: () => import('@/views/redirect'),
  children: [
    {
      path: 'monitoring',
      name: 'collection.monitoring',
      meta: {
        bg: false,
        break: true,
        title: '监控大屏',
      }
    },
    {
      path: 'statistics',
      name: 'collection.statistics',
      meta: {
        bg: true,
        break: true,
        title: '订单概览',
      },
      component: () => import('@/views/Collection/Statistics/index'),
    },
    {
      path: 'analyse',
      name: 'collection.analyse',
      meta: {
        bg: true,
        break: true,
        title: '订单分析',
      },
      component: () => import('@/views/Collection/Analyse/index'),
    },
    {
      path: 'orders',
      name: 'collection.orders',
      meta: {
        bg: true,
        break: true,
        title: '日均订单',
      },
      component: () => import('@/views/Collection/Orders/index'),
    },
    {
      path: 'pay',
      name: 'collection.pay',
      meta: {
        bg: false,
        break: true,
        title: '支付配置',
      },
      component: () => import('@/views/Collection/Pay/index'),
      redirect: {name: 'collection.pay.wx'},
      children: [
        {
          path: 'wx',
          name: 'collection.pay.wx',
          meta: {
            bg: false,
            break: true,
            title: '微信支付',
          },
          component: () => import('@/views/Collection/Pay/wx/index'),
        },
        {
          path: 'wx/config',
          name: 'collection.pay.wx.config',
          meta: {
            bg: false,
            break: true,
            title: '配置微信商户',
          },
          component: () => import('@/views/Collection/Pay/wx/wxConfig'),
        },
        {
          path: 'ali',
          name: 'collection.pay.ali',
          meta: {
            bg: false,
            break: true,
            title: '支付宝支付',
          },
          component: () => import('@/views/Collection/Pay/ali/index'),
        },
        {
          path: 'ali/config',
          name: 'collection.pay.ali.config',
          meta: {
            bg: false,
            break: true,
            title: '配置支付宝商户',
          },
          component: () => import('@/views/Collection/Pay/ali/aliConfig'),
        },
      ]
    },
    // {
    //   path: 'quickPay',
    //   name: 'collection.quickpay',
    //   meta: {
    //     bg: false,
    //     break: true,
    //     title: '支付配置',
    //   },
    //   component: () => import('@/views/Collection/QuickPay/index'),
    //   redirect: {name: 'collection.quickPay.ali'},
    //   children: [
    //     {
    //       path: 'ali',
    //       name: 'collection.quickPay.ali',
    //       meta: {
    //         bg: false,
    //         break: true,
    //         title: '支付宝支付',
    //       },
    //       component: () => import('@/views/Collection/QuickPay/ali/index'),
    //     },
    //     {
    //       path: 'ali/config',
    //       name: 'collection.pay.quickPay.ali.config',
    //       meta: {
    //         bg: false,
    //         break: true,
    //         title: '配置支付宝商户',
    //       },
    //       component: () => import('@/views/Collection/QuickPay/ali/aliConfig'),
    //     },
    //   ]
    // },
  ]
}
