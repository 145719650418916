/**
 * Created by fworld on 2019/9/11.
 */
import types from '../mutationTypes';
import {productAll} from '@/api/factory/research/product'
export default {
    state: {
        productNames: [],
    },
    mutations: {
        [types.SET_PRODUCT_NAME](state, productNames) {
            state.productNames = [{name:'全部', uuid:''}].concat(productNames)
        },
    },
    actions: {
        loadProductNames({state,commit}){
            if (state.productNames.length === 0) {
                productAll().then(res => {
                    commit(types.SET_PRODUCT_NAME, res.data || [])
                })
            }
        },

        clearProductNames({commit}){
            commit(types.SET_PRODUCT_NAME, [])
        },
    }
}
