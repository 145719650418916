import Vue from 'vue'

Vue.filter('formatDate', function (value) {
    if (!value) {
        return ''
    }
    let d = new Date(value * 1000);
    let year = d.getFullYear();
    let month = d.getMonth() + 1;
    month = month < 10 ? '0' + month : month
    let date = d.getDate();
    date = date < 10 ? '0' + date : date
    let h = d.getHours();
    h = h < 10 ? '0' + h : h;
    let m = d.getMinutes();
    m = m < 10 ? '0' + m : m;
    let s = d.getSeconds();
    s = s < 10 ? '0' + s : s;
    return `${year}-${month}-${date} ${h}:${m}:${s}`;
})

Vue.filter('getdate', function (value) {
    return value.split(' ')[0]
})
Vue.filter('gettime', function (value) {
    return value.split(' ')[1]
})

Vue.filter('status', function (start, end) {
    //0 未开始 1 正正在进行中 -1 已结束
    let current = new Date().getTime()
    start = start * 1000;
    end = end * 1000;
    if (current < start) {
        return 0
    }
    if (current > end) {
        return -1
    }
    if (current >= start && current <= end) {
        return 1
    }
})

Vue.filter('F1000', function (val) {

    if (typeof (val) === 'number') {

        return (val).toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,')

    }

})

Vue.filter('I1000', function (val) {

    if (typeof (val) === 'number') {
        return (val).toFixed(0).replace(/(\d)(?=(?:\d{3})+$)/g, '$1,')

    }

})

Vue.filter('formatMoney', function (value) {
    return (value / 100).toFixed(2)
})
