import shop from './marketing/shop'
import combination from './marketing/combination'
import qrcode from './marketing/qrcode'

export default {
  path: 'marketing',
  name: 'marketing',
  component: () => import('@/views/redirect'),
  meta: {
    title: '',
  },
  children: [
    {...combination},
    {...qrcode},
    {
      path: 'ticket',
      name: 'marketing.ticket',
      meta: {
        bg: false,
        break: true,
        title: '优惠券'
      }
    },
    {
      path: 'card',
      name: 'marketing.card',
      meta: {
        bg: false,
        break: true,
        title: '兑换卡'
      }
    },
    {...shop},

    {
      path: 'vip',
      name: 'marketing.vip',
      meta: {
        bg: false,
        break: true,
        title: '会员运营'
      }
    },
    {
      path: 'first_user',
      name: 'marketing.first_user',
      meta: {
        bg: false,
        break: true,
        title: '首单活动'
      }
    },
    {
      path: 'limit_free',
      name: 'marketing.limit_free',
      meta: {
        bg: false,
        break: true,
        title: '限时免费'
      }
    },
  ]
}
