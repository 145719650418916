const combination = {
  path: 'qrcode',
  name: 'marketing.qrcode',
  meta: {
    bg: false,
    break: true,
    title: '取货码'
  },
  redirect: {name: 'marketing.qrcode.list'},
  component: () => import('@/views/redirect'),
  children: [
    {
      path: 'list',
      name: 'marketing.qrcode.list',
      component: () => import('@/views/Marketing/Qrcode/index'),
    },
    {
      path: 'create',
      meta: {
        bg: false,
        break: true,
        title: '创建取货码'
      },
      name: 'marketing.qrcode.create',
      component: () => import('@/views/Marketing/Qrcode/create'),
    },
    {
      path: 'edit/:uuid',
      meta: {
        bg: false,
        break: true,
        title: '编辑取货码'
      },
      name: 'marketing.qrcode.edit',
      component: () => import('@/views/Marketing/Qrcode/edit'),
    },
    {
      path: 'items',
      meta: {
        bg: false,
        break: true,
        title: '条码列表'
      },
      name: 'marketing.qrcode.items',
      component: () => import('@/views/Marketing/Qrcode/items'),
    },

  ]
}

export default combination;
