import Vue from 'vue'
import Vuex from 'vuex'

import config from './modules/config';
import account from './modules/account';
import getters from './getters'
import factory from './modules/factory'
import marketing from './modules/marketing'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    config,
    factory,
    marketing,
    account
  },
  getters
})
