const shop = {
  path: 'shop',
  name: 'marketing.shop',
  meta: {
    bg: false,
    break: true,
      title: '周边商城'
  },
  component: () => import('@/views/Marketing/Shop/index'),
  redirect: { name: 'marketing.shop.goods' },
  children: [
    {
      name: 'marketing.shop.goods',
      path: 'goods',
      component: () => import('@/views/Marketing/Shop/Goods/index'),
      meta: { title: '商品管理' },
      redirect: { name: 'marketing.shop.goods.selling' },
      children: [
        {
          name: 'marketing.shop.goods.selling',
          path: 'selling',
          component: () => import('@/views/Marketing/Shop/Goods/selling'),
        },
        {
          name: 'marketing.shop.goods.create',
          path: 'detail',
          component: () => import('@/views/Marketing/Shop/Goods/detail'),
        },
        {
          name: 'marketing.shop.classification',
          path: 'classification',
          component: () => import('@/views/Marketing/Shop/Goods/classification'),
        },
        // {
        //   name: 'soldout',
        //   path: 'soldout',
        //   component: () => import('@/views/Marketing/Shop/Goods/soldout')
        // },
        // {
        //   name: 'instore',
        //   path: 'instore',
        //   component: () => import('@/views/Marketing/Shop/Goods/instore')
        // },
        // {
        //   name: 'commodity.detail',
        //   path: 'detail/:uuid',
        //   component: () => import('@/views/Marketing/Shop/Goods/detail'),
        //   redirect: { name: 'cmd.detail.base' },
        //   children: [
        //     {
        //       name: 'cmd.detail.base',
        //       path: 'base',
        //       component: () => import('@/views/Marketing/Shop/Goods/detail/base')
        //     },
        //     {
        //       name: 'cmd.detail.tpl',
        //       path: 'tpl',
        //       component: () => import('@/views/Marketing/Shop/Goods/detail/tpl')
        //     }
        //   ]
        // },
        // {
        //   name: 'commodity.classification',
        //   path: 'classification',
        //   component: () => import('@/views/redirect'),
        //   redirect: { name: 'cmd.classification.list' },
        //   children: [
        //     {
        //       name: 'cmd.classification.list',
        //       path: 'list',
        //       meta: { showBack: true },
        //       component: () => import('@/views/Marketing/Shop/Goods/classification')
        //     },
        //     {
        //       name: 'cmd.classification.edit',
        //       path: 'edit',
        //       component: () => import('@/views/Marketing/Shop/Goods/classification/edit')
        //     }
        //   ]
        // },

      ]
    },

    {
      name: 'shopSet',
      path: 'set',
      component: () => import('@/views/Marketing/Shop/Set/'),
      meta: { title: '商城设置' },
      redirect: { name: 'logistics' },
      children: [
        {
          name: 'logistics',
          path: 'logistics',
          component: () => import('@/views/Marketing/Shop/Set/logistics/'),
          redirect: { name: 'distribution' },
          children: [
            {
              name: 'distribution',
              path: 'distribution',
              meta: { title: '同城配送', isSubItem: true },
              component: () => import('@/views/Marketing/Shop/Set/logistics/distribution')
            },
            {
              name: 'expressage',
              path: 'expressage',
              meta: { title: '快递物流' },
              component: () => import('@/views/Marketing/Shop/Set/logistics/expressage')
            },
            {
              name: 'newExpressage',
              path: 'editor',
              meta: { title: '快递物流', showBack: true },
              component: () => import('@/views/Marketing/Shop/Set/logistics/newExpressage')
            },
            {
              name: 'takeTheir',
              path: 'takeTheir',
              meta: { title: '上门自提' },
              component: () => import('@/views/Marketing/Shop/Set/logistics/takeTheir')
            },
            {
              name: 'newTakeTheir',
              path: 'edit',
              meta: { title: '新建自提点', showBack: true },
              component: () => import('@/views/Marketing/Shop/Set/logistics/newTakeTheir')
            }
          ]
        },
        {
          path: 'applySetting',
          name: 'applySetting',
          component: () => import('@/views/Marketing/Shop/Set/applySetting/')
        },
        {
          path: 'universallySetting',
          name: 'universallySetting',
          component: () => import('@/views/Marketing/Shop/Set/universallySetting/')
        },
        {
          path: 'storeSetting',
          name: 'storeSetting',
          component: () => import('@/views/Marketing/Shop/Set/storeSetting/')
        }
      ]
    },
    // {
    //   path: 'order',
    //   name: 'shop.order',
    //   component: () => import('@/views/redirect'),
    //   meta: { title: '订单管理' },
    //   redirect: { name: 'shop.order.list' },
    //   children: [
    //     {
    //       name: 'shop.order.list',
    //       path: 'list',
    //       component: () => import('@/views/Marketing/Shop/Order'),
    //       meta: { isSubItem: true }
    //     },
    //     {
    //       path: 'detail/:id',
    //       name: 'order.detail',
    //       component: () => import('@/views/Marketing/Shop/Order/details')
    //     },
    //     {
    //       path: 'rights/:id',
    //       name: 'rights',
    //       component: () => import('@/views/Marketing/Shop/Order/details/rights')
    //     }
    //   ]
    // }
  ]
}
export default shop
