/**
 * Created by fworld on 2019/11/29.
 */


function setHTML() {
    let baseVal = 16
    let pageWidth = 1366
    let maxFontSize = 200
    let screenWidth = document.querySelector('html').offsetWidth
    let fontsize = screenWidth * baseVal / pageWidth
    if (fontsize > maxFontSize) {
        fontsize = maxFontSize
    }
    document.querySelector('html').style.fontSize = fontsize + 'px'
}

window.onload= setHTML()
window.onresize = setHTML()
